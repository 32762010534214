@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;700;900&display=swap");

/* https://codepen.io/tyleremiller/pen/mdBZJVZ?editors=0100 */

.mochibot-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  height: 180px;
  margin-left: 20px;
}
.mochibot-shape {
  display: flex;
  justify-content: center;
  width: 320px;
  height: 180px;
  z-index: 0;
}

.server-list {
  width: 48px;
  padding: 8px 4px;
  border-radius: 16px;
  background: radial-gradient(#000000 60%, #111111);
  border: 1px solid rgba(68, 23, 23, 0.05);
}

#s1,
#s2 {
  margin-bottom: 8px;
}

.server {
  box-sizing: border-box;
  --dim: 48px;
  height: var(--dim);
  width: var(--dim);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 0;
}

.server img {
  box-sizing: border-box;
  height: var(--dim);
  width: var(--dim);
  border-radius: calc(var(--dim) / 2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.notifs {
  position: relative;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 16px;
  z-index: 1;
}

/* notification messages */
.notifs p {
  position: absolute;
  top: -17px;
  left: 8px;
  text-align: center;
  font-weight: 700;
  z-index: 1;
}

.notif-2digit {
  transform: translate(-4px, 0px);
}

.notifs .pill {
  display: flex;
  opacity: 0.9;
}

.notifs .pill div {
  background-color: rgba(255, 41, 35, 1);
  height: 16px;
  width: 8px;
}

.end.left {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transform: translate(0, 0);
}

.end.right {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bot-intro-null {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.bot-intro-null img {
  object-fit: cover;
  --dim: 96px;
  height: var(--dim);
  width: var(--dim);
  border-radius: var(--dim);
  box-shadow: 0px 0px 64px 4px rgba(162, 0, 255, 0.4);
  background-color: #140723;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.bot-intro-message {
  color: white;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 4px rgba(158, 84, 200, 0.2);
  background-color: rgba(20, 7, 35, 0.9);
  width: 290px;
}

.bot-intro-title {
  width: 100%;
  display: flex;
  direction: row;
  flex-wrap: nowrap;
  margin-bottom: 8px;
}

.bot-intro-sender {
  margin: 0;
  margin-right: 8px;
  font-weight: 700;
  color: #9e54c8;
}

.timestamp {
  opacity: 0.5;
}

#s1 {
  animation: s1 16000ms ease-in-out infinite;
}
#s2 {
  animation: s2 16000ms ease-in-out infinite;
}
#s3 {
  animation: s3 16000ms ease-in-out infinite;
}

/* animation for avatars with notifications */
@keyframes s1 {
  0% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  2% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  4% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  51% {
    opacity: 0.6;
    transform: translate(-8px, -80px) rotate(385deg) scale(0.8);
  }
  62% {
    opacity: 0.6;
    transform: translate(-8px, -80px) rotate(385deg) scale(0.8);
  }
  100% {
    opacity: 0;
    transform: translate(7px, -20px) rotate(0deg) scale(0.3);
  }
}
@keyframes s2 {
  0% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  3% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  5% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  51% {
    opacity: 0.6;
    transform: translate(-140px, 63px) rotate(97deg) scale(0.8);
  }
  62% {
    opacity: 0.6;
    transform: translate(-140px, 63px) rotate(97deg) scale(0.8);
  }
  100% {
    opacity: 0;
    transform: translate(-20px, -7px) rotate(0deg) scale(0.3);
  }
}
@keyframes s3 {
  0% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  4% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  6% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  51% {
    opacity: 0.6;
    transform: translate(130px, -34px) rotate(212deg) scale(0.8);
  }
  62% {
    opacity: 0.6;
    transform: translate(130px, -34px) rotate(212deg) scale(0.8);
  }
  100% {
    opacity: 0;
    transform: translate(34px, 51px) rotate(0deg) scale(0.3);
  }
}

.bot-intro-null {
  animation: nullmain 16000ms ease-in-out infinite;
}
.bot-intro-null .bot-intro-message {
  animation: null-msg 16000ms ease-in-out infinite;
}

@keyframes nullmain {
  0% {
    opacity: 0;
    transform: translate(-82px, -138px) rotate(0deg) scale(0.7);
  }
  49% {
    opacity: 0;
    transform: translate(-82px, -138px) rotate(0deg) scale(0);
  }
  51% {
    opacity: 1;
    transform: translate(-82px, -138px) rotate(0deg) scale(1);
  }
  95% {
    opacity: 1;
    transform: translate(-82px, -138px) rotate(0deg) scale(1);
  }
  97% {
    opacity: 1;
    transform: translate(-82px, -146px) rotate(0deg) scale(1);
  }
  99% {
    opacity: 0;
    transform: translate(-82px, -98px) rotate(0deg) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-82px, -98px) rotate(0deg) scale(1);
  }
}
@keyframes null-msg {
  0% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0.8);
  }
  53% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0.8);
  }
  55% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  98% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  99% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0.8);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0.8);
  }
}

#s1 .notifs {
  animation: n1 16000ms ease-in-out infinite;
}
#s2 .notifs {
  animation: n2 16000ms ease-in-out infinite;
}
#s3 .notifs {
  animation: n3 16000ms ease-in-out infinite;
}

/* animations for notification pills */
@keyframes n1 {
  0% {
    opacity: 0;
    z-index: 1;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  6% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  8% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  54% {
    opacity: 0;
    transform: translate(-160px, -160px) rotate(720deg) scale(0);
  }
  100% {
    z-index: 1;
    opacity: 0;
    transform: translate(0px, -16px) rotate(0deg) scale(0);
  }
}
@keyframes n2 {
  0% {
    z-index: 1;
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  12% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  14% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  54% {
    opacity: 0;
    transform: translate(0px, 160px) rotate(540deg) scale(0);
  }
  100% {
    z-index: 1;
    opacity: 0;
    transform: translate(0px, -16px) rotate(0deg) scale(0);
  }
}
@keyframes n3 {
  0% {
    z-index: 1;
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  9% {
    opacity: 0;
    transform: translate(0px, 0px) rotate(0deg) scale(0);
  }
  11% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  50% {
    opacity: 1;
    transform: translate(0px, -16px) rotate(0deg) scale(1);
  }
  54% {
    opacity: 0;
    transform: translate(-40px, 60px) rotate(720deg) scale(0);
  }
  100% {
    z-index: 1;
    opacity: 0;
    transform: translate(0px, -16px) rotate(0deg) scale(0);
  }
}

#s1 .p-list {
  animation: n1p 16000ms ease-in-out infinite;
}
#s2 .p-list {
  animation: n2p 16000ms ease-in-out infinite;
}
#s3 .p-list {
  animation: n3p 16000ms ease-in-out infinite;
}

#msg01 {
  animation: msg01 16000ms ease-in-out infinite;
}
#msg02 {
  animation: msg02 16000ms ease-in-out infinite;
}
#msg03 {
  animation: msg03 16000ms ease-in-out infinite;
}
#msg04 {
  animation: msg04 16000ms ease-in-out infinite;
}
#msg05 {
  animation: msg05 16000ms ease-in-out infinite;
}
#msg06 {
  animation: msg06 16000ms ease-in-out infinite;
}
#msg07 {
  animation: msg07 16000ms ease-in-out infinite;
}
#msg08 {
  animation: msg08 16000ms ease-in-out infinite;
}
#msg09 {
  animation: msg09 16000ms ease-in-out infinite;
}
#msg10 {
  animation: msg10 16000ms ease-in-out infinite;
}
#msg11 {
  animation: msg11 16000ms ease-in-out infinite;
}
#msg12 {
  animation: msg12 16000ms ease-in-out infinite;
}
#msg13 {
  animation: msg13 16000ms ease-in-out infinite;
}
#msg14 {
  animation: msg14 16000ms ease-in-out infinite;
}
#msg15 {
  animation: msg15 16000ms ease-in-out infinite;
}
#msg16 {
  animation: msg16 16000ms ease-in-out infinite;
}
#msg17 {
  animation: msg17 16000ms ease-in-out infinite;
}
#msg18 {
  animation: msg18 16000ms ease-in-out infinite;
}
#msg19 {
  animation: msg19 16000ms ease-in-out infinite;
}
#msg20 {
  animation: msg20 16000ms ease-in-out infinite;
}
#msg21 {
  animation: msg21 16000ms ease-in-out infinite;
}
#msg22 {
  animation: msg22 16000ms ease-in-out infinite;
}
#msg23 {
  animation: msg23 16000ms ease-in-out infinite;
}
#msg24 {
  animation: msg24 16000ms ease-in-out infinite;
}

@keyframes msg01 {
  0% {
    opacity: 1;
  }
  14% {
    opacity: 1;
  }
  16% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg02 {
  0% {
    opacity: 0;
  }
  14% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  22% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg03 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  32% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg04 {
  0% {
    opacity: 0;
  }
  32% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  39% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg05 {
  0% {
    opacity: 0;
  }
  38% {
    opacity: 0;
  }
  39% {
    opacity: 1;
  }
  42% {
    opacity: 1;
  }
  43% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg06 {
  0% {
    opacity: 0;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 1;
  }
  46% {
    opacity: 1;
  }
  47% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg07 {
  0% {
    opacity: 0;
  }
  46% {
    opacity: 0;
  }
  47% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes msg08 {
  0% {
    opacity: 1;
  }
  23% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg09 {
  0% {
    opacity: 0;
  }
  23% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  26% {
    opacity: 1;
  }
  28% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg10 {
  0% {
    opacity: 0;
  }
  26% {
    opacity: 0;
  }
  28% {
    opacity: 1;
  }
  36% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg11 {
  0% {
    opacity: 0;
  }
  36% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg12 {
  0% {
    opacity: 0;
  }
  41% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  44% {
    opacity: 1;
  }
  45% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg13 {
  0% {
    opacity: 0;
  }
  44% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  47% {
    opacity: 1;
  }
  49% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg14 {
  0% {
    opacity: 0;
  }
  47% {
    opacity: 0;
  }
  49% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes msg15 {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  19% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg16 {
  0% {
    opacity: 0;
  }
  17% {
    opacity: 0;
  }
  19% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  31% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg17 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  31% {
    opacity: 1;
  }
  34% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg18 {
  0% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg19 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  41% {
    opacity: 1;
  }
  43% {
    opacity: 1;
  }
  44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg20 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg21 {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  46% {
    opacity: 1;
  }
  47% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg22 {
  0% {
    opacity: 0;
  }
  47% {
    opacity: 0;
  }
  48% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
