@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;700&display=swap");

@keyframes modal-shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.wiggle {
  animation: modal-shake 0.1s ease-in-out 2;
}