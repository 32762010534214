.stack {
  display: flex;
  flex-direction: column;
  width: 24px;
  margin: 0 auto;
  transform: scale(2.4);
}

.stack svg {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: -3px;
  opacity: 1;
}

#stack1 {
  transform: translate(0px, -24px);
}

#stack1,
#stack5 {
  margin-top: -12px;
}

#stack1 {
  animation: stack1 1200ms ease-in-out infinite;
  z-index: 5;
}
#stack2 {
  animation: stack2 1200ms ease-in-out infinite;
  z-index: 4;
}
#stack3 {
  animation: stack3 1200ms ease-in-out infinite;
  z-index: 3;
}
#stack4 {
  animation: stack4 1200ms ease-in-out infinite;
  z-index: 2;
}
#stack5 {
  animation: stack5 1200ms ease-in-out infinite;
}

@keyframes stack1 {
  0% {
    /*  opacity: 0; */
    transform: translate(0px, -24px) scale(0.8, 1.2);
  }
  18% {
    opacity: 1;
    transform: translate(0px, 18px) scale(0.8, 1.2);
  }
  24% {
    opacity: 1;
    transform: translate(0px, 12px) scale(1.2, 0.8);
  }
  30% {
    opacity: 1;
    transform: translate(0px, 12px) scale(1, 1);
  }
  50% {
    /*  opacity: 0; */
    transform: translate(0px, 12px) scale(1, 1);
  }
  90% {
    /*  opacity: 0; */
    transform: translate(0px, -36px) scale(0.8, 1.4);
  }
  100% {
    /*  opacity: 0; */
    transform: translate(0px, -36px) scale(1, 1);
  }
}
@keyframes stack2 {
  0% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
  14% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
  20% {
    opacity: 1;
    transform: translate(0px, 18px) scale(1.2, 0.8);
  }
  24% {
    opacity: 1;
    transform: translate(0px, 12px) scale(1, 1);
  }
  50% {
    /*  opacity: 0; */
    transform: translate(0px, 12px) scale(1, 1);
  }
  100% {
    /*  opacity: 0; */
    transform: translate(0px, 3px) scale(1, 1);
  }
}
@keyframes stack3 {
  0% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
  10% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
  16% {
    opacity: 1;
    transform: translate(0px, 18px) scale(1.2, 0.8);
  }
  20% {
    opacity: 1;
    transform: translate(0px, 12px) scale(1, 1);
  }
  50% {
    /*  opacity: 0; */
    transform: translate(0px, 12px) scale(1, 1);
  }
  100% {
    /*  opacity: 0; */
    transform: translate(0px, 3px) scale(1, 1);
  }
}
@keyframes stack5 {
  0% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
  10% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
  16% {
    opacity: 1;
    transform: translate(0px, 48px) scale(1, 1);
  }
  24% {
    opacity: 0;
    transform: translate(0px, 48px) scale(2, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 24px) scale(0.6, 1.24);
  }
}
@keyframes stack4 {
  0% {
    opacity: 0;
    transform: translate(0px, 3px) scale(1, 1);
  }
  50% {
    opacity: 0;
    transform: translate(0px, 15px) scale(0.6, 1.4);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 3px) scale(1, 1);
  }
}
