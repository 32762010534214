@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;700;900&display=swap");

/* Source */
/* https://codepen.io/tyleremiller/pen/ExwMjJV?editors=0100 */

.anim-container {
  width: 320px;
}

.form {
  position: relative;
  width: 320px;
  padding: 10px;
  padding-top: 18px;
  height: 440px;
  border-radius: 24px;
  background: radial-gradient(#000000 60%, #111111);
  border: 1px solid rgba(245, 245, 245, 0.05);
}

.thread {
  position: absolute;
  top: 64px;
  width: 298px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-content: flex-start;
  opacity: 1;
  animation: thread 24000ms ease-in-out infinite;
}

.thread .message {
  width: 100%;
  margin-top: 4px;
  display: flex;
}

.info {
  display: flex;
  align-items: center;
}

.avatar {
  --dim: 32px;
  height: var(--dim);
  width: var(--dim);
  border-radius: calc(var(--dim) / 2);
  background-color: rgba(17, 17, 17, 1);
  border: 1px solid rgba(245, 245, 245, 0.1);
  flex-shrink: 0;
  margin-right: 8px;
  margin-top: 2px;
  margin-bottom: 3px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  --dim: 32px;
  border-radius: calc(var(--dim) / 2);
}

.message.system {
  font-size: 14px;
  color: #989898;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-top: 16px;
  margin-bottom: 2px;
  margin-left: 0;
  background-color: none;
  border: none;
}

.message.player {
  border-radius: 8px;
  background-color: rgba(245, 245, 245, 0.1);
  border: 1px solid rgba(245, 245, 245, 0.1);
  padding: 8px;
  margin-left: 0;
  /* backdrop-filter: blur(8px); */
}

.message .sender {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4px;
  letter-spacing: 0.02em;
}

.message.player.p1 {
  box-shadow: 0px 0px 16px 4px rgba(218, 160, 73, 0.2);
  background-color: #171106;
}

.message.player.p2 {
  box-shadow: 0px 0px 16px 4px rgba(96, 175, 59, 0.2);
  background-color: #0d1509;
}

.message.player.p3 {
  box-shadow: 0px 0px 16px 4px rgba(84, 103, 200, 0.2);
  background-color: #090e27;
}

.message.player.p4 {
  box-shadow: 0px 0px 16px 4px rgba(158, 84, 200, 0.2);
  background-color: #140723;
}

.message.p2 .sender {
  color: #60af3b;
}

.message.p3 .sender {
  color: #5467c8;
}

.message.p4 .sender {
  color: #9e54c8;
}

.null .sender {
  color: #9e54c8;
  font-size: 14px;
}

.player .sender {
  color: #daa049;
}

.message .content {
  line-height: 115%;
  letter-spacing: 0.03em;
}

.content {
  margin: 0;
  padding: 0;
}

.title {
  display: flex;
  flex-direction: column;
}

.title p {
  font-weight: 900;
  font-size: 24px;
  line-height: 100%;
  margin: 0;
  flex-shrink: 0;
}

.intro {
  color: #989898;
}

p.team {
  font-style: italic;
}

.divider {
  background-color: #565656;
  height: 0px;
  width: 100%;
  margin-top: 8px;
}

.icon {
  margin-right: 4px;
}

.reactions {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-radius: 12px;
  padding-left: 2px;
  padding-top: 1px;
  height: 24px;
  width: 24px;
  background-color: rgba(255, 255, 255, 0.1);
}

.counter {
  margin-left: 2px;
  margin-right: 2px;
}

.msg-content {
  display: inline-block;
  width: 100%;
  margin: 0;
  line-height: 100%;
}

.thread {
  animation: thread 20000ms ease-in-out infinite;
}

#m6 p {
  letter-spacing: 0;
  width: 100%;
}

@keyframes thread {
  0% {
    transform: translate(0px, 346px);
    opacity: 0;
  }
  9% {
    transform: translate(0px, 346px);
    opacity: 0;
  }
  10% {
    /* 346-(17+16+2) = 311 */
    transform: translate(0px, 311px);
    opacity: 1;
  }
  19% {
    /* 346-(17+16+2) = 311 */
    transform: translate(0px, 311px);
    opacity: 1;
  }
  20% {
    /* 311-(56+4) = 251 */
    transform: translate(0px, 251px);
    opacity: 1;
  }
  29% {
    /* 311-(56+4) = 251 */
    transform: translate(0px, 251px);
    opacity: 1;
  }
  30% {
    /* 251-(17+16+2) = 216 */
    transform: translate(0px, 216px);
    opacity: 1;
  }
  39% {
    /* 251-(17+16+2) = 216 */
    transform: translate(0px, 216px);
    opacity: 1;
  }
  40% {
    /* 216-(56+4) = 156 */
    transform: translate(0px, 156px);
    opacity: 1;
  }
  49% {
    /* 216-(56+4) = 156 */
    transform: translate(0px, 156px);
    opacity: 1;
  }
  50% {
    /* 156-(56+4) = 96 */
    transform: translate(0px, 96px);
    opacity: 1;
  }
  59% {
    /* 156-(56+4) = 96 */
    transform: translate(0px, 96px);
    opacity: 1;
  }
  60% {
    /* 96-(17+16+2) = 61 */
    transform: translate(0px, 61px);
    opacity: 1;
  }
  69% {
    /* 96-(17+16+2) = 61 */
    transform: translate(0px, 61px);
    opacity: 1;
  }
  70% {
    /* 61-(56+4) = 1 */
    transform: translate(0px, 0px);
    opacity: 1;
  }
  95% {
    /* 61-(56+4) = 1 */
    transform: translate(0px, 0px);
    opacity: 1;
  }
  100% {
    transform: translate(0px, -40px);
    opacity: 0;
  }
}

#m2 {
  animation: msg2 20000ms ease-in-out infinite;
}
#m3 {
  animation: msg3 20000ms ease-in-out infinite;
}
#m4 {
  animation: msg4 20000ms ease-in-out infinite;
}
#m5 {
  animation: msg5 20000ms ease-in-out infinite;
}
#m6 {
  animation: msg6 20000ms ease-in-out infinite;
}
#m7 {
  animation: msg7 20000ms ease-in-out infinite;
}
#m1 {
  animation: msg1 20000ms ease-in-out infinite;
}

@keyframes msg2 {
  0% {
    opacity: 0;
  }
  19% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg3 {
  0% {
    opacity: 0;
  }
  29% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg4 {
  0% {
    opacity: 0;
  }
  39% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg5 {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg6 {
  0% {
    opacity: 0;
  }
  59% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg7 {
  0% {
    opacity: 0;
  }
  69% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes msg1 {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#emo1 {
  animation: emo2 20000ms ease-in-out infinite;
}
#emo2 {
  animation: emo1 20000ms ease-in-out infinite;
}

@keyframes emo1 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  41% {
    opacity: 0;
    transform: scale(0);
  }
  42% {
    opacity: 1;
    transform: scale(1.2);
  }
  42.5% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes emo2 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  51% {
    opacity: 0;
    transform: scale(0);
  }
  52% {
    opacity: 1;
    transform: scale(1.2);
  }
  52.5% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#emo3 {
  animation: emo3 20000ms ease-in-out infinite;
}
#emo4 {
  animation: emo4 20000ms ease-in-out infinite;
}

@keyframes emo3 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  83% {
    opacity: 0;
    transform: scale(0);
  }
  84% {
    opacity: 1;
    transform: scale(1.2);
  }
  84.5% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes emo4 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  76% {
    opacity: 0;
    transform: scale(0);
  }
  77% {
    opacity: 1;
    transform: scale(1.2);
  }
  77.5% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#emo5 {
  animation: emo5 20000ms ease-in-out infinite;
}

@keyframes emo5 {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  56% {
    opacity: 0;
    transform: scale(0);
  }
  57% {
    opacity: 1;
    transform: scale(1.2);
  }
  57.5% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
