@import url("./variables.css");
@import url("./normalize.css");

html {
  scroll-behavior: smooth;
}

body {
  /* background: var(--color-green); */
  font-family: "Ubuntu", sans-serif;
  background: black;
  color: white;
  line-height: 1.3;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  /* border-bottom: 1px solid #ffffff; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.web3modal-modal-lightbox {
  z-index: 200 !important;
}

button {
  cursor: pointer;
}

body {
  --w3m-z-index: 100;
}

/* snagged from here - https://stackoverflow.blog/2021/05/31/shipping-confetti-to-stack-overflows-design-system/ */
.bg-confetti-animated {
  /* background-repeat: repeat-x; */
  animation: hue-rotate 3000ms infinite;
  background-position: top -10px center;
  background-image: url("../img/confetti.svg");
}

@keyframes hue-rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(180deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
