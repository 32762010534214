@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;700&display=swap");

/* Source */
/* https://codepen.io/tyleremiller/pen/GRMPOBV?editors=1100 */

.checkin-anim-container {
  height: 330px;
  display: flex;
  width: 320px;
  overflow: hidden;
}
.checkin-form {
  position: relative;
  align-self: flex-end;
  width: 320px;
  height: 132px;
  padding: 8px;
  border-radius: 24px;
  background: radial-gradient(#000000 60%, #111111);
  border: 1px solid rgba(245, 245, 245, 0.05);
}

.checkin-thread {
  position: absolute;
  width: 280px;
  top: 20px;
  left: 0px;
  padding: 0 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  /* transform: translate(0px, 545px); */
  opacity: 1;
  animation: checkin-thread 24000ms ease-in-out infinite;
  font-size: 14px;
  font-weight: 400;
}

.checkin-thread .checkin-message {
  margin-top: 8px;
  width: 100%;
}

.checkin-info {
  display: flex;
  position: absolute;
  width: 95%;
  bottom: 0;
  align-self: flex-end;
  align-items: center;
}

.checkin-player-info {
  bottom: 0;
  width: 100%;
  text-align: right;
  margin-right: 4px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  letter-spacing: 0.02em;
  margin-top: 0 auto;
}

.checkin-avatar {
  --dim: 32px;
  height: var(--dim);
  width: var(--dim);
  border-radius: calc(var(--dim) / 2);
  background-color: rgba(17, 17, 17, 1);
  border: 1px solid rgba(245, 245, 245, 0.05);
  flex-shrink: 0;
}

.checkin-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.token {
  --dim: 16px;
  height: var(--dim);
  width: var(--dim);
  border-radius: calc(var(--dim) / 2);
  margin-right: 8px;
  flex-shrink: 0;
  border: 1px solid #ffb800;
  background-color: #fff8b5;
  display: flex;
}

.token-box {
  display: grid;
}

.token {
  grid-column: 1/1;
  grid-row: 1/1;
}

.token svg {
  padding: 1px 0px 0px 1px;
}

.checkin-message {
  border-radius: 8px;
  background-color: rgba(245, 245, 245, 0.1);
  border: 1px solid rgba(245, 245, 245, 0.1);
  padding: 8px;
  margin: 0;
  /* flex-shrink: 0;
  flex-grow: 0; */
  /* backdrop-filter: blur(8px); */
}

.checkin-message .checkin-sender {
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.02em;
  margin-top: 0;
}

.checkin-message.checkin-null {
  margin-right: 32px;
  box-shadow: 0px 0px 16px 4px rgba(158, 84, 200, 0.2);
  margin-left: 4px;
  background-color: #140723;
}

.checkin-message.checkin-player {
  margin-right: 4px;
  margin-left: 32px;
  box-shadow: 0px 0px 16px 4px rgba(218, 160, 73, 0.2);
  background-color: #171106;
}

.checkin-null .checkin-sender {
  color: #9e54c8;
  font-size: 14px;
}

.checkin-player .checkin-sender {
  color: #daa049;
}

.checkin-message .checkin-content {
  line-height: 115%;
  letter-spacing: 0.03em;
  margin: 0;
}

@keyframes checkin-thread {
  /* Start */
  0% {
    transform: translate(0, 0px);
    opacity: 0;
  }
  /* Hold */
  9% {
    transform: translate(0, 0px);
    opacity: 0;
  }
  /* Msg1 in - (54) */
  10% {
    transform: translate(0, 0px);
    opacity: 1;
  }
  /* Msg1 hold */
  20% {
    transform: translate(0, 0px);
    opacity: 1;
  }
  /* Msg2 in - (54+8+74) = 136 */
  21% {
    transform: translate(0, -80px);
    opacity: 1;
  }
  /* Msg2 hold */
  31% {
    transform: translate(0, -80px);
    opacity: 1;
  }
  /* Msg3 in - (136+8+73) = 217 */
  32% {
    transform: translate(0, -160px);
    opacity: 1;
  }
  /* Msg3 hold */
  42% {
    transform: translate(0, -160px);
    opacity: 1;
  }
  /* Msg4 in - (217+8+75) = 300 */

  43% {
    transform: translate(0, -240px);
    opacity: 1;
  }
  /* Msg4 hold */
  53% {
    transform: translate(0, -240px);
    opacity: 1;
  }
  /* Msg5 in - (300+8+54) = 362 */
  54% {
    transform: translate(0, -310px);
    opacity: 1;
  }
  /* Msg5 hold */
  64% {
    transform: translate(0, -310px);
    opacity: 1;
  }
  /* Msg6 in - (362+8+93) = 463 */
  65% {
    transform: translate(0, -400px);
    opacity: 1;
  }
  /* Msg6 hold */
  75% {
    transform: translate(0, -400px);
    opacity: 1;
  }
  /* Msg7 in */
  76% {
    transform: translate(0, -480px);
    opacity: 1;
  }
  /* Msg7 hold */
  86% {
    transform: translate(0, -480px);
    opacity: 1;
  }
  /* End in */
  87% {
    transform: translate(0, -599px);
    opacity: 0;
  }
  /* End hold */
  100% {
    transform: translate(0, -599px);
    opacity: 0;
  }
}

#checkin-m1 {
  animation: checkin-message1 24000ms ease-in-out infinite;
}
#checkin-m2 {
  animation: checkin-message2 24000ms ease-in-out infinite;
}
#checkin-m3 {
  animation: checkin-message3 24000ms ease-in-out infinite;
}
#checkin-m4 {
  animation: checkin-message4 24000ms ease-in-out infinite;
}
#checkin-m5 {
  animation: checkin-message5 24000ms ease-in-out infinite;
}
#checkin-m6 {
  animation: checkin-message6 24000ms ease-in-out infinite;
}
#checkin-m7 {
  animation: checkin-message7 24000ms ease-in-out infinite;
}

@keyframes checkin-message1 {
  /* Out at Msg4 */
  0% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes checkin-message2 {
  /* Out at Msg5 */
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  21% {
    opacity: 1;
  }
  53% {
    opacity: 1;
  }
  54% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes checkin-message3 {
  /* Out at Msg6 */
  0% {
    opacity: 0;
  }
  31% {
    opacity: 0;
  }
  32% {
    opacity: 1;
  }
  64% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes checkin-message4 {
  /* Out at Msg7 */
  0% {
    opacity: 0;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes checkin-message5 {
  0% {
    opacity: 0;
  }
  53% {
    opacity: 0;
  }
  54% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes checkin-message6 {
  0% {
    opacity: 0;
  }
  64% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes checkin-message7 {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.checkin-player-info,
.token-box {
  opacity: 0;
  animation: textin 24000ms ease-in-out infinite;
}

@keyframes textin {
  0% {
    opacity: 0;
    transform: translate(0px, 0px);
  }
  77% {
    opacity: 0;
    transform: translate(0px, 4px);
  }
  78% {
    opacity: 0;
    transform: translate(0px, 4px);
  }
  80% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  92% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  93% {
    opacity: 0;
    transform: translate(0px, 0px);
  }
  100% {
    opacity: 0;
    transform: translate(0px, 0px);
  }
}

#t2 {
  animation: tokenup2 24000ms ease-in-out infinite;
  opacity: 1;
  transform: translate(0, 0px) scale(1);
}
#t3 {
  animation: tokenup3 24000ms ease-in-out infinite;
  opacity: 1;
  transform: translate(0, 0px) scale(1);
}
#t4 {
  animation: tokenup4 24000ms ease-in-out infinite;
  opacity: 1;
  transform: translate(0, 0px) scale(1);
}
#t5 {
  animation: tokenup5 24000ms ease-in-out infinite;
  opacity: 1;
  transform: translate(0, 0px) scale(1);
}
#t6 {
  animation: tokenup6 24000ms ease-in-out infinite;
  opacity: 1;
  transform: translate(0, 0px) scale(1);
}

@keyframes tokenup2 {
  0% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  83% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  83.75% {
    opacity: 0;
    transform: translate(0, -48px) scale(2);
  }
  83.875% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
}
@keyframes tokenup3 {
  0% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  83.5% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  84.25% {
    opacity: 0;
    transform: translate(0, -48px) scale(2);
  }
  84.375% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
}
@keyframes tokenup4 {
  0% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  84% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  84.75% {
    opacity: 0;
    transform: translate(0, -48px) scale(2);
  }
  84.875% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
}
@keyframes tokenup5 {
  0% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  84.5% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  85.25% {
    opacity: 0;
    transform: translate(0, -48px) scale(2);
  }
  85.375% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
}
@keyframes tokenup6 {
  0% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  85% {
    opacity: 1;
    transform: translate(0, 0px) scale(1);
  }
  85.75% {
    opacity: 0;
    transform: translate(0, -48px) scale(2);
  }
  85.875% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0px) scale(1);
  }
}
